<template>
  <div class="listBox">
    <div class="checkbox" style="margin-top: 30px">
      <div>
        <span style="margin-right: 20px">{{ title }}</span>
        <a-checkbox
          style="margin-right: 50px"
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
        <a-checkbox-group
          v-model="pitchAreaCheckboxList"
          name="checkboxgroup"
          :options="areaCheckboxList"
          @change="onChange"
        />
      </div>
      <div v-if="title === '节点类型'" class="inpOrBtn">
        <div class="inpOrBtn" style="margin-right: 50px">
          <a-input
            v-model="phoneNumber"
            placeholder="请输入手机号"
            style="margin-right: 15px"
            :allowClear="true"
          />
          <a-button @click="searchUser" type="primary"> 查询用户 </a-button>
        </div>
        <a-button @click="refreshAllList" type="primary"> 刷新全部 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
const pitchAreaCheckboxList = [];
export default {
  name: "HeaderCheckbox",
  props: ["areaCheckboxList", "title"],
  data() {
    return {
      //多选选中的数组
      pitchAreaCheckboxList,
      //全选默认不被选中
      indeterminate: false,
      checkAll: false,
      //输入框的值
      phoneNumber: "",
    };
  },
  methods: {
    //全选按钮触发事件
    onCheckAllChange(e) {
      Object.assign(this, {
        pitchAreaCheckboxList: e.target.checked ? this.areaCheckboxList : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.$emit("checkedArr", this.pitchAreaCheckboxList);
    },
    //选项改变触发事件
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length &&
        checkedList.length < this.areaCheckboxList.length;
      this.checkAll = checkedList.length === this.areaCheckboxList.length;
      this.$emit("checkedArr", this.pitchAreaCheckboxList);
    },
    //查询用户点击触发事件
    searchUser() {
      const num = this.phoneNumber;
      const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(num)) {
        alert("请填写正确的手机号！");
      } else {
        this.$emit("phoneNumber", num);
      }
    },
    //点击全部刷新调用父组件的方法
    refreshAllList() {
      this.$parent.refreshAllList();
    },
  },
};
</script>
<style scoped>
.title {
  text-align: center;
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inpOrBtn {
  display: flex;
}
</style>
