<template>
  <a-table
    :columns="columns"
    :data-source="tableList"
    :row-key="(record) => record.id"
    :loading="loading"
    style="margin-top: 30px"
  >
    <span slot="name" slot-scope="text, record">
      <a style="margin-right: 20px">{{ record.name }}</a>
    </span>
    <span slot="use_status_d" slot-scope="text, record" class="d_box">
      <span v-if="record.use_status_d === '暂停使用'" class="red"></span>
      <span v-else class="green"></span>
    </span>
    <span slot="action" slot-scope="text, record">
      <!-- <a @click="refresh(record)" style="margin-right: 20px">刷新</a> -->
      <a @click="nodeDetails(record)">查看详情</a>
    </span>
  </a-table>
</template>
<script>
//列表配置
const columns = [
  {
    title: "",
    dataIndex: "use_status_d",
    key: "use_status_d",
    align: "center",
    scopedSlots: { customRender: "use_status_d" },
  },
  {
    title: "编号",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "名称",
    key: "name",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "区域",
    key: "region",
    dataIndex: "region",
    align: "center",
  },
  {
    title: "类型",
    key: "type",
    dataIndex: "type",
    align: "center",
  },
  {
    title: "运行状态",
    key: "run_status",
    dataIndex: "run_status",
    align: "center",
  },
  {
    title: "使用状态",
    key: "use_status",
    dataIndex: "use_status",
    align: "center",
  },
  {
    title: "资源池",
    key: "resource_pools",
    dataIndex: "resource_pools",
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "ListTable",
  props: ["tableList", "loading"],
  data() {
    return {
      columns,
    };
  },
  methods: {
    //刷新某条信息
    // refresh(row) {
    //   const infoId = this.tableList.findIndex((item) => item.id === row.id);
    //   const newObj = {
    //     id: "999",
    //     name: "替换了",
    //     region: "替换了",
    //     type: "替换了",
    //     run_status: "替换了",
    //     use_status: "替换了",
    //     use_status_d: "替换了",
    //     resource_pools: "替换了",
    //   };
    //   this.tableList.splice(infoId, 1, newObj);
    // },
    //跳转到用户详情
    nodeDetails(row) {
      this.$router.push({ path: "/nodeDetails", query: { id: row.id } });
    },
  },
};
</script>
<style scoped>
.green {
  display: block;
  width: 15px;
  height: 15px;
  background: green;
  border-radius: 50%;
}
.red {
  display: block;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
}
.d_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
