<template>
  <div class="listBox">
    <h1 class="title">运维管理系统控制台</h1>
    <header-checkbox
      :title="'节点区域'"
      :area-checkbox-list="areaCheckboxList"
      v-on:checkedArr="areaCheckboxArr"
    />
    <header-checkbox
      :title="'节点类型'"
      :area-checkbox-list="typeCheckboxList"
      v-on:checkedArr="typeCheckboxArr"
      @phoneNumber="getPhoneNumber"
    />
    <list-table :table-list="tableList" :loading="loading" />
  </div>
</template>
<script>
//引入头部多选组件
import headerCheckbox from "./components/headerCheckbox.vue";
//引入列表表格组件
import listTable from "./components/listTable.vue";
//引入封装接口
import { getNodeList } from "../../server/api";
//节点区域渲染列表
const areaCheckboxList = ["香港", "美国", "日本", "新加坡", "俄罗斯", "其他"];
//节点类型渲染列表
const typeCheckboxList = ["高级", "普通", "特惠", "其他"];
export default {
  //注册组件
  components: {
    headerCheckbox,
    listTable,
  },
  data() {
    return {
      //渲染节点区域多选的数组
      areaCheckboxList,
      //渲染节点类型多选的数组
      typeCheckboxList,
      //节点区域的选中数组
      areaCheckboxConfirmArr: [],
      //节点类型的选中数组
      typeCheckboxConfirmArr: [],
      //渲染列表的数组
      tableList: [],
      //节点列表loading加载开关
      loading: false,
    };
  },
  created() {
    this.getNodeListFunc();
  },
  methods: {
    //获取节点列表
    getNodeListFunc() {
      this.loading = true;
      const params = {
        regions: this.areaCheckboxConfirmArr,
        types: this.typeCheckboxConfirmArr,
      };
      getNodeList(params).then((res) => {
        this.tableList = res.nodes;
        this.loading = false;
      });
    },
    //刷新全部事件重新获取列表
    refreshAllList() {
      this.getNodeListFunc();
    },
    //获取子组件传来的选中数组（节点区域）
    areaCheckboxArr(data) {
      this.areaCheckboxConfirmArr = [];
      data.forEach((item) => {
        this.areaCheckboxConfirmArr.push(item);
      });
      this.getNodeListFunc();
    },
    //获取子组件传来的选中数组（节点类型）
    typeCheckboxArr(data) {
      this.typeCheckboxConfirmArr = [];
      data.forEach((item) => {
        this.typeCheckboxConfirmArr.push(item);
      });
      this.getNodeListFunc();
    },
    //获取子元素传来的搜索框的手机号
    getPhoneNumber(data) {
      this.$router.push({ path: "/userDetails", query: { id: data } });
    },
  },
};
</script>
<style scoped>
.title {
  text-align: center;
}
</style>
